.mobile_btn_wrap{
    padding: 10px 10px ;
    background-color: #0b255b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: fixed;
    z-index: 50;
}
.mobile_btn_wrap .logo{
    width: 100px;
    height: 22px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.mobile_btn_wrap .logo img{
    width: 100%;
    height: 100%;
}
.header_top_box.m{
    margin-top: 20px;
}
.mobileadmin{
    font-weight: 900;
    color: #d9d9d9;
    text-align: right;
    padding: 0px 10px;
}
.mnavigation{
    background-color: #fff;
    border-right: 1px solid #d9d9d9;
    border-bottom: 1px solid #d9d9d9;
    position: fixed;
    height: 100vh;
    top: 52px;
    left: 0;
    z-index: 39;
    padding: 30px 10px 10px 10px;
}
.mnavigation>ul{
    background: unset;
}
.search_bar.m{
    width: 100%;

}
.mnavigation>ul>li{
    font-size: 16px;
    font-weight: 700;
}
.shopcart>a{
    font-size: 20px;
    color: #fff;
}
.msort_box {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 10px 0;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

  }
  
  .msort_box > div {
    width: 25%;
    position: relative;
  }
  .msort_box > div>button{
    width: 100%;
  }
  
  .msort_box::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
.sortbtnss{
    height: 32px;
}
.mobile_sort .sortbtn{
    display: none;
}
.mobile_btn{
    background: unset;
    border: unset;
    color: #fff;
    font-weight: 900;
    font-size: 20px;
    padding: 0px;
}
.ml30{
    margin-left: 30px;
}
.backbtn{
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 1000;
    cursor: pointer;
}
.backbtn>a{
    font-size: 20px;
    color: #fff;
}