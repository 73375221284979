@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
* {
  box-sizing: border-box;
}
#root {
  font-family:
    'Nanum Gothic', 'Malgun Gothic', 'Dotum', 'Gulim', 'Helvetica Neue',
    'Helvetica', 'AppleGothic', 'Verdana', 'Arial', sans-serif !important;
  font-size: 16px;
}

/* react-slick 라이브러리 */
.slick-slide {
  /* margin: 0 10px; */
}
.slick-list {
  /* margin: 0 -10px; */
}

.ant-table-cell {
  padding: 5px !important;
}
.anchorwrap .mainanc {
  display: block;
  width: 100%;
  color: #0b255b;
}

.anchorwrap .ant-anchor-link-title-active .mainanc {
  color: #1677ff;
  font-weight: 900;
}
/* 폰트사이즈 */
.f12 {
  font-size: 12px !important;
}
.f13 {
  font-size: 13px !important;
  line-height: 13px;
}
.f14 {
  font-size: 14px !important;
}
.f18 {
  font-size: 18px !important;
  font-weight: 900;
}
.f22 {
  font-size: 22px !important;
  font-weight: 900;
  line-height: 22px;
}
.f20 {
  font-size: 20px !important;
  font-weight: bold;
  line-height: 20px;
}
.tx {
  text-decoration: line-through;
  color: #676565;
}
.grid3 .tx {
  margin-right: 3px;
}
.tx > span:last-child {
  text-decoration: unset !important;
}
.none {
  display: none !important;
}
.flex {
  display: flex;
}
.h22 {
  height: 22px;
  line-height: 22px;
}
.h24 {
  height: 24px;
  line-height: 24px;
}
.h30 {
  height: 30px;
  line-height: 30px;
}
.flex.j {
  align-items: center;
  justify-content: space-between;
}
.flex.a {
  align-items: center;
}
.flex.c {
  flex-direction: column;
}
.flex.s {
  align-items: flex-start;
}

.pricebox {
  position: relative;
}
.dcvalue {
  background-color: #0b255b;
  color: #fff;
  padding: 2px;
  font-size: 12px;
  line-height: 12px;
  height: 18px;
}
.w100 {
  width: 100% !important;
}
/* 컬러 */
.navi {
  background-color: #0b255b;
  color: #fff;
}
.grey {
  background-color: #a1a1a1;
  color: #fff;
}
/* 공통 */

.pageTbox {
  margin-bottom: 20px;
  position: relative;
}
.pageTCbox {
  margin-bottom: 20px;
  position: relative;
  padding: 30px 0 20px 0;
}
.pageTbox .pagetitle {
  padding: 0;
  margin: 0;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 40px;
  color: #080808;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  text-transform: capitalize;
}
.pageTCbox .pagetitle {
  font-size: 40px;
  color: #080808;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5em;
  position: relative;
  text-align: center;
}
.pageTCbox .pagetitle:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: '';
  background-color: #0b255b;
  left: 50%;
  margin-left: -30px;
}
.addressbox {
  padding: 10px;
  border: 1px solid #4b6cb7;
  border-radius: 10px;
  box-shadow:
    inset 0px 1px 0px rgba(255, 255, 255, 0.8),
    0px 1px 3px;
  padding-left: 10px;
  margin-bottom: 10px;
}
.addressbox:last-child {
  margin-bottom: 0px;
}
.mpgt {
  font-weight: 700;
  font-size: 30px;
}
.pagetitle span {
  display: block;
  font-size: 0.5em;
  line-height: 1.3;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 4px;
  line-height: 3em;
  padding-left: 0.25em;
  color: rgba(0, 0, 0, 0.4);
  padding-bottom: 10px;
}
.pagetitle em {
  font-style: normal;
  font-weight: 600;
}

.pagetitle:before {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  content: '';
  background-color: #0b255b;
}

.main {
  padding-top: 120px;
  min-height: calc(100vh - 140px);
  position: relative;
}
.ptx {
  padding-top: 0px !important;
}
.login_logo > a {
  width: 100%;
  height: 100%;
  display: block;
}
.login_logo > a > img {
  width: 100%;
  height: 100%;
}
/* .innerban{
    width: 100%;
    height: 400px;
}
.innerban img{
width: 100%;
height: 100%;
} */
/* innerSearch */
.sdiv {
  padding: 20px 10px 20px 0;
  display: flex;
  justify-content: left;
}
.sdiv_s {
  width: 100px;
  margin-right: 10px;
}
.sdiv .innerS {
  width: 200px;
  border: none;
  border-radius: 0px;
  border-bottom: 2px solid #4b6cb7;
  margin-right: 10px;
}

.inventory_input {
  display: flex;
  margin-bottom: 20px;
}
.inventory_input input {
  width: 300px;
}
.check_wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.cart_check_wrap {
  display: flex;
  justify-content: left;
  width: calc(50% - 10px);
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #4b6cb7;
}
.cart_check_wrap:nth-child(1) {
  border-top: 1px solid #4b6cb7;
}
.cart_check_wrap:nth-child(2) {
  border-top: 1px solid #4b6cb7;
}
.cart_check_wrap .count_wrap {
  margin-right: 20px;
}
.cart_wrap {
  width: 100%;
}
.cart_box {
  width: 75%;
  display: flex;
}
.cart_box_img {
  width: 80px;
  height: 80px;
  margin-right: 10px;
}
.cart_box li {
  width: 100%;
}
.cart_box_img img {
  width: 100%;
  height: 100%;
}

/* 장바구니 추가 버튼 */
.cartbtn {
  font-size: 16px;
  padding: 5px;
  text-align: center;
}
.cartbtn .anticon {
  margin-inline-start: 0px !important;

  vertical-align: 0px;
}
.cartdelet {
  color: #ff0000;
}

/* 푸터 */
.contain {
  position: relative;

  min-height: 100vh;
}
.footer {
  height: 140px;
  position: relative;
  overflow: hidden;
}
.footercon {
  width: 100%;
  z-index: 10;
}
.footer_bg_line {
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #4b6cb7;
}
.footer_main {
  display: flex;
  align-items: center;
}
.footer_d {
  margin-right: 100px;
}
.footer_d_wrap {
  padding: 10px 0;
}
.footer_logo {
  width: 164px;
  margin-right: 20px;
  height: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footer_logo img {
  width: 100%;
}

/* 푸터탑 */
.footer_top {
  display: flex;
  height: 60px;
  padding: 10px 10px 10px 0px !important;
  align-items: center;
}
.footer_top li {
  margin-right: 20px;
  font-weight: bold;
}
.footer_top li a {
  color: #000;
}
.footerline {
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 175px;
  height: 60px;
  border-bottom: 1px solid #4b6cb7;
}

.slidebox {
  height: 600px;
}
/* 헤더 */
.header .logowrap {
  width: 190px;
  /* border: 10px solid #0b255b;
    border-top-width: 30px !important;
    border-bottom-width: 30px !important; */
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-left: 10px;
}

.search_btn {
  cursor: pointer;
}
.maintabs .ant-tabs {
  padding: 0px 20px;
}
.logo img {
  width: 100%;
  line-height: 60px;
}
.displaybox {
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}
.header_b {
  display: flex;
  justify-content: right;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.header_box {
  padding: 30px 10px !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ant-popover-arrow {
  display: none !important;
}
.search_bar {
  margin: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
}
.searchbar_title {
  font-size: 20px;
  font-weight: 900;
}
.ant-popover-inner {
  border-radius: 0px !important;
}
.ant-popover {
  width: 720px;
  left: 50% !important;
  transform: translate(-50%, 0) !important;
  top: 120px !important;
}
.searchInner {
  margin-top: 30px;
}
.search_b {
  padding: 10px;
  cursor: pointer;
}
.search_bar > .flex > input {
  height: 50px;
  border: none;
  border-bottom: 3px solid #4b6cb7;
  background-color: unset;
  border-radius: 0px;
  margin-right: 20px;
}
.search_bar > .flex > input:focus {
  border-color: #4b6cb7;
  box-shadow: unset;
  border-inline-end-width: unset;
  outline: 0;
}
.search_bar > .flex > input:hover {
  border-color: #4b6cb7;
}
.search_bar > .flex > a {
  border: none;
  font-size: 20px;
  font-weight: 900;
  color: #000;
  height: 50px;
}
.function_head {
  width: 160px;
  display: flex;
  justify-content: space-between;
}
.function_head svg,
.function_head a {
  color: #fff;
}
.function_head_box {
  margin-left: 0px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  color: #fff;
}
.needsbox {
  flex-wrap: wrap;
}
.needs img {
  width: 240px;
  height: 180px;
}
.needs {
  margin-right: 10px;
  margin-bottom: 10px;
}
.function_head svg {
  font-size: 26px;
}

/* 탑 카테고리 네비게이션 메뉴 */
.navigation {
  width: calc(100% - 330px);
  border-bottom: unset !important;
  justify-content: center;
  align-items: center;
  background: unset;
  height: 100%;
}
.ant-menu-item-selected {
  color: #fff !important;
  text-decoration: underline !important;
  background-color: #4b6cb7 !important;
  box-shadow:
    1px 1px 5px #182848,
    -1px -1px 5px #4b6cb7,
    inset 1px 1px 5px #4b6cb7,
    inset -1px -1px 5px #4b6cb7;
}
.ant-menu-item:hover {
  color: #fff !important;
  text-decoration: underline !important;
  /* box-shadow: 1px 1px 5px #182848, -1px -1px 5px #4b6cb7, inset 1px 1px 5px #4b6cb7, inset -1px -1px 5px #4b6cb7; */
}
.navigation li {
  height: 100%;
  width: 130px;
  color: #fff;
  font-size: 22px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-family: 'Roboto', sans-serif !important;
}
.navigation li:last-child {
  padding-right: 0px !;
}
.ant-menu-item-selected::after,
.ant-menu-item:hover::after,
.ant-menu-item::after {
  display: none;
}
.header_top_bg {
  height: 30px;
  width: 100%;
  background-color: #d9d9d9;
}
.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  border-bottom: 1px solid #676565;
  background-color: #0b255b;
}
.header_wrap {
  display: flex;
  height: 120px;
  padding-left: 0px !important;
  align-items: center;
  justify-content: space-between;
}
.header_bottom_bg {
  height: 67px;
  position: absolute;
  z-index: -1;
  width: 100%;
  background-color: #404d69;
}
.menuli {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff !important;
}
.menuicon {
  width: 50px;
  height: 50px;
}
.menuicon img {
  width: 100%;
  height: 100%;
}
/* .titlew{
    color: #fff !important;
} */
.mainbgwrap {
  position: relative;
  padding: 40px 0;
  width: 100%;
}
.mainbg {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #d9d9d9;
}
.header_top_box {
  height: 30px;
  padding: 0px 10px;
  height: 30px;
  display: flex;
  justify-content: right;
  align-items: center;
}
.header_top_box > li {
  font-size: 14px;
  color: #000;
  cursor: pointer;
}
.header_top_box > li > a {
  color: #000;
}
.breacrumb {
  padding: 10px 0;
}

/* 쇼핑몰 */

.brandt {
  margin-right: 10px;
  color: #a1a1a1;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.grid1 .brandt {
  margin-left: 5px;
}
.grid2 .brandt {
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
}

.gridbtnwrap {
  width: 100px;
}
.productwrap2_wrap_div {
  height: 60px;
}
.product_d,
.other_wrap,
.pricebox {
  color: #0b255b;
}
.bold {
  font-weight: bold;
}
.grid3 .brand {
  color: #000;
  margin-right: 5px;
}
.grid3 .neeedsbtn {
  margin-right: 0 !important;
}
.grid2 .product_d {
  font-size: 18px;
  color: #0b255b;
  font-weight: bold;
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 0;
}
/* .grid2 .brandt, .grid2 .valueid, .grid2 .product_d.detail{
color: #000;
} */
.flexc {
  flex-direction: column;
}
.product_d.detail {
  display: inline-block;
  color: #a1a1a1;
  line-height: 18px;
  font-weight: 600;
  overflow: hidden;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.other_wrap .ant-tag {
  padding: 0;
  margin-inline-end: 5px;
  width: 35px !important;
  height: 18px;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
}
.ant-row {
  width: 100%;
}
.functionbtn_wrap {
  display: flex;
}

/* shop페이지 */
.ant-checkbox-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.productbox {
  position: relative;
  padding: 5px;
}

.grid2 {
  padding: 10px;
  transition: all 0.5s ease-out;
  border: 1px solid #4b6cb7;
  margin-bottom: 10px;
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
}
.grid3wrap {
  width: calc(100% - 280px);
  justify-content: space-between;
}
.product_de {
  width: 95%;
  display: none;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
.grid2 .other_wrap {
  margin-right: 10px;
}
.grid3wrap {
  display: flex;
  flex-direction: column;
  width: calc(100% - 80px);
}
.grid3top,
.grid3bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.productbox.grid1 {
  display: flex;
  flex-direction: column;
  width: calc(16% - 8px);
  margin-right: 10px;
  border: 1px solid #4b6cb7;
  transition: all 0.5s ease-out;
  margin-bottom: 10px;
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.12),
    0 1px 2px rgba(0, 0, 0, 0.24);
}
.productbox.grid3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid #4b6cb7;
  transition: all 0.5s ease-out;
  padding: 10px 0;
}
.productbox.grid3:last-child {
  border-bottom: 1px solid #4b6cb7;
}

.grid3 .product_pic {
  width: 70px;
  height: 70px;
}
.grid3 .grid31 {
  width: calc(100% - 445px);
}
.grid31 .product_d,
.grid31 .valueid {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.grid3 .grid32 {
  justify-content: right;
  width: 100px;
  text-align: right;
}
.grid33 {
  width: 75px;
}

.grid34 {
  width: 100px;
}
.grid35 {
  width: 100px;
}
.grid35 button {
  width: 100%;
}
.grid3 .dcvalue {
  position: relative;
  top: unset;
  left: unset;
  height: 18px;
}

.productbox.grid1:nth-child(6n) {
  margin-right: 0;
}
.grid1 .pName {
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 18px;
}
.grid2Pname {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.grid1 .product_pic {
  height: 200px;
}
.grid2Pname .pName {
  font-size: 18px;
  color: #0b255b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
}
.pad10 {
  padding: 10px 0;
}
.grid2 .other_wrap {
  display: flex;
}
.grid2 .pricebox {
  display: flex;
  align-items: center;
}
.grid2 .tx {
  margin-left: 10px;
}
.grid2 .dcvalue {
  position: relative !important;
  top: unset;
  left: unset;
  padding: 2px;
  font-size: 12px;
  height: 16px;
  line-height: 12px;
}
.grid2 .othertag {
  margin-right: 10px;
}

.product_pic img {
  width: 100%;
  height: 100%;
}
.productbox.grid2 {
  display: flex;
  flex-direction: column;
  width: calc(50% - 10px);
}
.product_pic_bg {
  width: 100%;
  height: 100%;
  background-color: #d9d9d9;
  position: absolute;
  display: block;
  opacity: 0;
  transition: all 0.5s;
}
.grid2:hover .product_pic_bg {
  opacity: 0.7;
}
.grid2:hover .product_de {
  display: flex;
  justify-content: left;
}
.productbox.grid2:nth-child(2n) {
  margin-left: 10px;
}
.grid2 .product_pic {
  width: 100%;
  position: relative;
  display: block;
  height: 500px;
}
.abpostion {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.product_wrap {
  height: calc(100% - 105px);
  position: relative;
}
.product_wrap.wide .productbox {
  width: 100% !important;
}
.shop {
  padding-left: 0px !important;
}
.npd {
  padding: 5px !important;
}
.postmodal {
  position: relative;
}
.shop_div {
  display: flex;
  min-height: calc(100vh - 260px);
  height: 100%;
}
.grid1 .pinfobox,
.grid3 .pinfobox {
  flex-wrap: wrap;
}
.grid2 .pinfobox .product_d {
  color: #a1a1a1 !important;
  font-weight: 400;
}

.pinfobox > p {
  margin-right: 5px;
  line-height: 18px;
}
/* 좌측 네비게이션  */
.shop_sort {
  width: 190px;
  margin-right: 40px;
  background-color: #0b255b;
}
.shop_sort > .shop_sort {
  padding-left: 20px;
}
.sortTitle {
  color: #fff;
  padding: 20px 0px 20px;
  margin-left: -20px;
  font-weight: 900;
  font-size: 22px;
  /* border-bottom: 1px solid #676565; */
}
.sortTitle > span {
  padding-left: 20px;
}
/* 왼쪽 네비게이션 색상 들어가는 부분 */
.sort_navi > li {
  cursor: pointer;
  /* background-color: #0b255b; */
  border-bottom: 2px solid #4b6cb7;
}
.sort_navi > li:first-child {
  border-top: 2px solid #4b6cb7;
}
.snavili {
  display: flex;
}
/* 원 혹은 세모 */
/* .triangle{
    width: 0px;
    height: 0px;
    margin-right: -30px;
    border-top: 30px solid transparent;
    border-left: 30px solid #4b6cb7;
    border-bottom: 30px solid transparent;
} */
.triangle {
  width: 30px;
  height: 60px;
  margin-right: -30px;
  border-radius: 0 30px 30px 0;
  background-color: #4b6cb7;
}
.sort_navi > li > a {
  width: 100%;
  height: 62px;
  display: block;
  color: #fff;
  font-weight: bold;
  line-height: 55px;
  font-size: 20px;
}

/* .sort_navi>li:hover{
    background-color: #0b255b
} */
.sort_navi > li:hover a,
.sort_navi > li:hover {
  color: #fff;
  font-weight: 900;
}
/* .sort_navi>li.active{
    background-color: #0b255b
} */
.sort_navi > li.active a,
.sort_navi > li.active {
  color: #fff;
  font-weight: 900;
}
.sort_navi > li.active a {
  display: block;
  background-color: #4b6cb7;
  box-shadow:
    inset 0px 1px 0px rgba(255, 255, 255, 0.8),
    0px 1px 3px;
  padding-left: 10px;
}
.shop_content {
  width: calc(100% - 230px);
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
}
.shop_content_box {
  min-height: 400px;
}
.shop_content_1 {
  width: calc(100% - 200px);
  position: relative;
}
.shop_main {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #4b6cb7;
}
.valuenum {
  margin-left: 10px;
}
.shop_img_wrap {
  width: 400px;
}
.shop_main .shop_img {
  width: 400px;
  height: 400px;
}
.shop_main .shop_img img {
  width: 100%;
  height: 100%;
}
.shop_main_detail .ant-tabs-tab {
  font-weight: 900;
  font-size: 18px;
}
.shop_main_detail {
  width: calc(100% - 420px);
}

/* 별점 */
.ant-rate {
  font-size: unset;
  line-height: 16px;
}
.ant-rate-star {
  font-size: 16px;
  margin-inline-end: 2px;
}
.shop_d_t {
  border-bottom: 1px solid #4b6cb7;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
}
.shop_d_t p {
  font-size: 20px;
  font-weight: 900;
}
.shop_d_t_t {
  font-size: 22px;
  font-weight: 900;
  margin-bottom: 10px;
}
.shop_d_t .s {
  color: #a1a1a1;
  margin-bottom: 10px;
}

.shop_d_box_t {
  font-size: 16px;
  font-weight: 900;
}
.shop_d_box_d {
  font-size: 14px;
  font-weight: 900;
  margin-right: 10px;
  color: #a1a1a1;
  display: flex;
}
.shop_d_box_d p {
  margin-right: 10px;
}

.shop_d_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.shop_d_box button,
.shop_d_box > a {
  width: calc(33.333% - 5px);
  height: 50px;
  font-size: 22px;
  font-weight: 900;
}
.shop_d_box > a > button,
.cartbuybtn {
  width: 100% !important;
  background-color: #0b255b;
  color: #fff;
}
.cartbuybtn {
  width: 170px !important;
  height: 50px !important;
  font-size: 22px;
  font-weight: 900;
}
.shop_d_d {
  justify-content: left;
  margin-bottom: 10px;
}
.dcprice {
  display: flex;
  margin-right: 20px;
  align-items: center;
  font-size: 14px;
  color: #a1a1a1;
}
.percent {
  margin-right: 10px;
}
.cost {
  text-decoration: line-through;
  margin-right: 5px;
}
.shop_d_p {
  padding: 20px 0;
  display: flex;
}
.shop_d_p .realp {
  color: #000;
  margin-right: 5px;
  font-size: 20px;
  font-weight: 900;
}
.shop_d_p .realp.morep {
  font-size: 20px;
  font-weight: bold;
  color: #0b255b;
}
.realp span {
  font-size: 14px;
  color: #a1a1a1;
  margin-left: 10px;
}
.imgslider {
  width: 100%;
  display: flex;
}

.imgslider > div {
  width: 80px;
  height: 80px;
  padding: 5px;
  cursor: pointer;
}
.imgslider > div img {
  width: 100%;
  height: 100%;
}
.productinfo_wrap {
  display: flex;
}
.productinfo_wrap > input {
  width: 300px;
}

.product_box {
  width: calc(25% - 10px);
  padding: 10px;
}

.shop .check_group {
  flex-direction: column;
}
.shop .check_group > label {
  margin-inline-start: 0px;
}
.sort_box {
  display: flex;
  margin-bottom: 30px;
  width: 100%;
  transition: all 1s;
  background-color: #c6d5fc;
  padding: 20px 10px;
  position: relative;
  border-radius: 5px;
}

.sort_box.hide {
  width: 100%;
  padding: 0px;
  height: 0px;
  overflow: hidden;
  transition: all 1s;
  margin: 0px;
}

/* bg-white border border-[#4b6cb7] rounded-[5px] */
.sort_box > li {
  width: calc(20% - 10px);
  margin: 0px 5px;
  background-color: #fff;
  border: 1px solid #4b6cb7;
  border-radius: 5px;
}
.sort_box > li > p {
  font-weight: 900;
  font-size: 18px;
  /* margin-bottom: 10px; */
}
.filterwrap {
  display: flex;
  flex-wrap: wrap;
}
.filterwrap > div {
  margin-right: 10px;
  width: 70px;
}
.filterbtn {
  border-radius: 20px;

  border: 1px solid #aec3f3;
}
.filterbtn .anticon {
  font-size: 10px;
  color: #676565;
}
.msort_grid {
  width: 30px;
  height: 30px;
}
.sortbtn {
  border-radius: 1px;
  margin-right: 20px;
  height: 40px;
  background-color: #4b6cb7;
  color: #fff;
}
.sortbtnss {
  padding: 5px;
  background-color: #4b6cb7;
  color: #fff;
}
.sortbtns {
  border-radius: 1px;
  height: 210px !important;
  width: 60px;
  background-color: #4b6cb7;
  color: #fff;
  transition: all 1s;
}
.tempBtns {
  /* height: 170px !important; */
  border-radius: 1px;
  background-color: #4b6cb7;
  color: #fff;
  transition: all 1s;
}
.tempBtns.hide {
  height: 0px;
  overflow: hidden;
  transition: all 1s;
}

.sortbtns.hide {
  height: 0px;
  overflow: hidden;
  transition: all 1s;
}

.sortbtns:hover {
  border: unset;
  color: #fff !important;
}
/* 소팅 관련  */
.search_com {
  width: 100%;
  height: 170px;
  /* border: 1px solid #4b6cb7;
    border-radius: 5px;
    background-color: #fff; */
  border-top: 1px solid #7488b6;
}
.arraylength {
  font-size: 18px;
  font-weight: 700;
  padding: 10px 0;
}
.msort_box .search_com {
  position: absolute;

  z-index: 30;
}
.sort_tag_box {
  margin-bottom: 20px;
}
.sort_searched_box {
  padding: 15px;
}
.sort_searched_box p {
  font-size: 16px;
}
.sort_searched_box > li {
  cursor: pointer;
}
.sort_searched_box > li.selected {
  background-color: #676565;
  font-weight: 900;
  color: #fff;
}
.sorting_list,
.sorting_list_grid,
.sorting_list_btn {
  display: flex;
}
/* 주석 해제가 필요한지? */
/* .searchresult{
    
    min-height: 60vh;
} */
.ant-card-body {
  border-top: 1px solid #4b6cb7 !important;
}
.searchresult .sorting_list {
  margin-top: 20px;
  margin-bottom: 20px;
}
.ant-card-bordered {
  border: 1px solid #cecece;
}
.cardwrap {
  position: relative;
}
.cardbtn {
  position: absolute;
  padding: 0px;
  right: 24px;
  bottom: 24px;
  border: none;
}
.needs img {
  border-top: 1px solid #cecece;
  border-right: 1px solid #cecece;
  border-left: 1px solid #cecece;
}
.sorting_list {
  width: 100%;
}
.sorting_list_grid {
  justify-content: right;
}
.sorting_list_btn {
  width: calc(100% - 150px);
  height: 40px;
}
.sorting_list_btn li {
  width: 20%;
}
.sorting_list_btn button {
  width: 100%;
  height: 100%;
  border-radius: 2px;
  border-top: 1px solid #4b6cb7;
  border-left: 1px solid #4b6cb7;
  border-bottom: 1px solid #4b6cb7;
}
.sorting_list_btn li:last-child button {
  border-right: 1px solid #4b6cb7;
}
.searchresult .sorting_list_btn {
  width: 100%;
}
.searchTopic {
  text-align: center;
  padding: 20px 0;
  font-size: 24px;
}
.cateArray {
  display: flex;
  background-color: #f3f3f3;
}
.cateArrayHead {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  background-color: #f3f3f3;
  padding: 15px 0;

  border-bottom: 1px solid #4b6cb7;
}
.cateArray > div {
  font-size: 13px;
  width: 20%;
  padding: 10px;
  border-right: 1px solid #4b6cb7;
  cursor: pointer;
}
.pl10 {
  padding-left: 10px;
}
.pl20 {
  padding-left: 40px;
}
.cateArray > div:last-child {
  border-right: none;
}
.cateArray .clicked {
  font-weight: bold;
}
.sorting_list_grid {
  width: 150px;
}
.sorting_list_grid > li {
  width: 40px;
  height: 40px;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  padding: 5px;
  border-radius: 2px;
}
.sorting_list_grid > li.clicked {
  background-color: #0b255b;
  color: #fff;
}
.sorting_list_btn button.clicked {
  background-color: #0b255b;
  color: #fff;
}
/* 색상변경 */
.menu-bar {
  z-index: 1001;
  position: fixed;
  top: 0;
  right: -300px; /* 초기에 오른쪽으로 숨김 */
  width: 100px;
  height: 100vh;
  background-color: #c6d5fc;
  transition: right 0.3s ease-in-out;
  border-left: 1px solid #a1a1a1;
}
/* 색상변경 */
.footer_bg {
  width: 100%;
  /* height: 160px; */
  background-color: #c6d5fc;
}
.menu-bar.open {
  right: 0; /* 열릴 때 오른쪽으로 이동 */
}
.toggle-button {
  position: fixed;
  padding: 5px;
  top: 50vh;
  right: 0px;
  background-color: #c6d5fc;
  color: #fff;
}

.toggle-button.opens {
  right: 100px;
}

.menu-content {
  padding: 15px;
}
.menu-content button {
  width: 100%;
  height: 45px;
  padding: 5px;
  font-size: 18px;
}
.ant-badge {
  font-size: 20px;
}

.menu-content ul {
  list-style: none;
  padding: 0;
}

.menu-content li {
  margin-bottom: 10px;
}
.hidebtn,
.showbtn {
  width: 30px;
}
.showbtn {
  position: absolute;
  right: 0px;
}

/* 회원가입 */
.allbox {
  width: 600px;
  margin: auto;
}
.check .ant-form-item-control-input-content {
  display: flex;
  justify-content: center !important;
}
.check label {
  text-align: center;
  width: 180px !important;
}
.ant-checkbox + span {
  width: 160px !important;
  color: #000;
}
.signup_wrap {
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
}
.sign_logo {
  padding-top: 20px;
  width: 300px;
  margin: auto;
  padding-bottom: 50px;
}
.sign_btn,
.login_btn,
.login_sign_btn {
  width: 100%;
}
.join_contain {
  width: 700px !important;
}
.join_contain label {
  width: 130px;
}
.join_logo {
  display: block;
  text-align: center;
  margin-bottom: 40px;
}
.joininput_wrap {
  width: 100%;
  height: 50px;
}
.signbtn {
  width: 100px;
  height: 50px;
  border-radius: unset;
  background-color: #0b255b;
  color: white;
}
.signbtn.l {
  width: 160px;
}
.joininput {
  height: 50px;
  width: 100%;
  border-radius: unset;
  margin-right: 10px;
}
.joininput.s {
  width: calc(100% - 10px);
}
.ant-input {
  color: #000 !important;
}
.joininput_wrap.n .ant-input-wrapper,
.joininput_wrap.n .ant-input-affix-wrapper {
  height: 100%;
}
.joininput_wrap.n .ant-select-selection-item,
.joininput_wrap.n .anticon {
  color: #000 !important;
}
.check {
  width: 100%;
}

/* 장바구니 , 카운터 버튼 */
.product_wrap .count_wrap,
.product_wrap .neeedsbtn,
.productwrap1 .count_wrap,
.productwrap1 .neeedsbtn {
  margin-right: 10px;
}
.counterbtrn_wrap {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.grid1 .counterbtrn_wrap {
  justify-content: space-between;
}
.grid1 .counterbtrn_wrap > button {
  width: calc((100% - 130px) / 2) !important;
}
.grid2 .counterbtrn_wrap {
  margin-top: 0px;
}
.grid2 .counterbtrn_wrap > button {
  width: 40px;
}
.shop_main_detail .ant-tabs {
  margin-top: 10px;
}
.countwrap {
  display: flex;
}
.countwrap > span {
  margin-right: 10px;
}
.shop_main_detail .ant-tabs .ant-tabs-content-holder {
  height: 150px;
}
.count_wrap {
  display: flex;
}
.counterbtn {
  padding: 0px 5px;
  height: 24px;
  z-index: 20;
  width: 30px;
  height: 30px;
  border-radius: 0px;
  border: 1px solid #aec3f3 !important;
}

.btnstyle {
  width: 100px;
  height: 30px;
  padding: 0px;
  text-align: center;
  font-size: 14px;
  line-height: 30px;
  border-radius: 0px !important;
  border: 1px solid #aec3f3 !important;
}
.btnstyle.c {
  background-color: #4b6cb7;
  color: #fff;
}
.sbtnstyle {
  width: 80px;
  height: 30px;
  border-radius: 0px !important;
  border: 1px solid #aec3f3 !important;
}
.producttitle {
  font-size: 18px;
  font-weight: bold;
  padding: 10px 0;
  border-bottom: 1px solid #4b6cb7;
  margin-bottom: 10px;
}
.ssbtnstyle {
  padding: 0;
  text-align: center;
  width: 50px;
  height: 30px;
  border-radius: 0px !important;
  border: 1px solid #aec3f3 !important;
}
.sbtnstyle.active {
  background-color: #0b255b;
  color: #fff;
}
.counter {
  color: #000;
  display: block;
  width: 40px;
  line-height: 30px;
  text-align: center;
  height: 30px;
  border-top: 1px solid #aec3f3;
  border-bottom: 1px solid #aec3f3;
  padding: 0px 10px;
  font-weight: bold;
}

.content_detail .ant-tabs-nav-list {
  width: 100%;
}
.content_detail .ant-tabs-tab {
  width: 20%;
  font-size: 18px;
  font-weight: 900;
  text-align: center !important;
  justify-content: center;
}
/* 검색 */
.search_box {
  padding: 10px;
  border: 1px solid #4b6cb7;
  margin-bottom: 10px;
}
/* 로그인 페이지 */
.login_sign_btn {
  margin-top: 20px;
}
.backbg {
  width: 100%;
  background: linear-gradient(
    to right,
    #4b6cb7,
    #182848
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height: 100vh;
}
.login_contain .ant-form-item-control-input span {
  border-color: #000;
  border-radius: unset;
}
.login_contain,
.join_contain {
  background-color: #fff;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
  width: 500px;
  padding: 100px 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.login_contain input {
  width: 100% !important;
}
.login_logo {
  width: 80%;
  text-align: center;
  margin: auto;
}
.loginbtn {
  width: 100%;
  background-color: #0b255b;
  margin-bottom: 10px;
  height: 50px;
  padding: 0;
}
.loginbtn > a {
  display: block;
  width: 100%;
  line-height: 50px;
  height: 100%;
}
/* 글작성 */
.productSelectwrap {
  margin-bottom: 20px;

  margin-top: 20px;
}
.productSelect {
  width: 150px;
  border-radius: 1px !important;
  margin-right: 5px;
  margin-bottom: 5px;
}
.productSelect.brand {
  width: 145px;
}
.productSelect.con {
  width: calc(100% - 100px);
}
.ql-container {
  min-height: 300px;
}
.productSelect.de {
  width: 770px;
  margin-right: 0px;
}
.productSelect .ant-select-selector {
  border-radius: 1px !important;
}
.submitbtn {
  position: absolute;
  right: 0;
  top: 0;
}
.submitbtns {
  float: right;
  margin-top: 10px;
}
/* 추천상품 */
.shopcompare {
  display: flex;
  width: 100%;
  margin: 20px 0;
}
.shopcompare img {
  width: 100%;
  height: 100%;
}
.shopcomaprebox {
  width: calc(20% - 10px);
  margin-right: 10px;
}
.shopcomaprebox:nth-child(5n) {
  margin-right: 0;
}
.compareimg {
  width: 100%;
  height: 150px;
}
.shopcompareT {
  font-size: 14px !important;
  color: #a1a1a1;
  font-weight: normal !important;
  padding: 10px 0px;
}
.ant-affix {
  background-color: #fff;
}

.qnacon img {
  width: 100%;
}
/* 비교 모달 */
.comparewrap {
  display: flex;
  flex-wrap: wrap;
  height: 70vh;
}
.compare_imgbox {
  width: 100%;
}
.comparewrap img {
  width: 100%;
}
.comparebox {
  width: calc(33.33% - 20px);
  margin-right: 20px;
}
.comparebox:nth-child(3n) {
  margin-right: 0px;
}
.rececntT {
  margin-bottom: 10px;
  text-align: center;
  color: #000;
}
.recentul {
  overflow-y: scroll;
  max-height: 60vh;
}
.recentul {
  -ms-overflow-style: none; /* 인터넷 익스플로러 */
  scrollbar-width: none; /* 파이어폭스 */
}
.recentul::-webkit-scrollbar {
  display: none;
}
.recentwrap {
  border: 1px solid #4b6cb7;
}
.recentbox {
  width: 70px;
  height: 70px;
}
.recentbox > div {
  width: 67px;
  height: 67px;
}
.recentbox img {
  width: 100%;
  height: 100%;
}

/* 마이페이지 */
.infobox_wrap {
  display: flex;
  align-items: center;
}
.infobox {
  width: 140px;
  text-align: left;
  padding: 10px;
}
.infoinputbox {
  width: calc(100% - 140px);
}
.infoinputbox input {
  width: 60%;
}

.totalp_wrap {
  display: flex;
  align-items: center;
  width: calc(100% - 40px);
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  padding: 20px 0;
}
.totalp {
  font-size: 18px;
  font-weight: 900;
}

/* 카테고리 설정 */
.catebox {
  width: 100%;
  height: 50px;
  font-size: 18px;
  padding: 5px 10px;
  border: 1px solid #4b6cb7;
  margin-bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.catebtnwrap {
  display: flex;
}
.inputBoxWrapper {
  display: flex;
  flex-direction: column;
}
.inputboxwrap {
  display: flex;
}
.addcatebtn {
  width: 100%;
  font-size: 18px;
  font-weight: 900;
  padding: 10px;
  height: auto;
}
.cateinputbox {
  padding: 10px 5px;
  background-color: #a1a1a1;
  width: 100%;
  border: 1px solid #4b6cb7;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
}
.cateinputbox.sub {
  padding: 10px 5px 10px 40px;
}
.cateinputbox > input {
  border-radius: 0px;
  width: 400px;
}
.openbtn {
  cursor: pointer;
  width: 30px;
  height: 50px;
  text-align: center;
  padding: 10px;
  border: 1px solid #4b6cb7;
}
.subcategorylist {
  display: flex;
  padding: 5px 10px 5px 40px;
  justify-content: space-between;
}
.subcategorylist button {
  padding: 5px;
  font-size: 12px;
}
.cateinfo {
  display: flex;
}
.cateinfo .nameinput {
  width: 300px;
}
/* 주문페이지  */
.buy_header {
  font-size: 24px;
  font-weight: 900;
  padding: 20px 0;
  text-align: center;
}
.buyimg {
  width: 80px;
  height: 80px;
  margin-right: 20px;
}
.buyname {
  width: calc(100% - 320px);
}
.buyimg img {
  width: 100%;
  height: 100%;
}
.buycount,
.buyprice {
  width: 100px;
  text-align: center;
}

.buybox {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #4b6cb7;
  padding: 10px;
}
.buywrap .buybox:first-child {
  border-top: 1px solid #4b6cb7;
}
.buydiv {
  width: calc(100% - 420px);
}
.buywrap {
  width: 100%;
  padding: 10px;
  border: 1px solid #4b6cb7;
  margin: 10px 0;
  border-radius: 20px;
}
.buycontain {
  position: relative;
}
.smallT {
  width: 100%;
  padding: 5px 0;
  border-bottom: 1px solid #4b6cb7;
  font-size: 22px;
  font-weight: 900;
  margin-bottom: 10px;
}
.buybtnwrap {
  position: absolute;
  right: 0;
  top: 0;
  width: 400px;
  padding: 10px;
}
.addresswrap,
.addressbtnwrap {
  margin-bottom: 10px;
}
.addresswrap input {
  width: 200px;
}
.addresswrap.phone input {
  width: 100px;
}
.addresswrap.long input {
  width: calc(50% - 5px);
}
.fixed {
  position: fixed;
  right: 20px;
}
.fix {
  background-color: #182848a8;
}
.header.fix .ant-menu-item-selected {
  background-color: #0b255b !important;
  box-shadow:
    1px 1px 5px #182848,
    -1px -1px 5px #0b255b,
    inset 1px 1px 5px #0b255b,
    inset -1px -1px 5px #0b255b;
}
.mainslide {
  width: 100%;
}
.divbox {
  display: flex;
  margin-bottom: 10px;
}
.buytitle {
  margin-right: 10px;
  width: 90px;
}
.buybtn {
  width: 100%;
  border-radius: 1px;
  height: 50px;
}
.waybox {
  display: flex;
  align-items: center;
}
.waywrap button {
  width: 33.333%;
  padding: 5px;
}
.waybox .active {
  background-color: #0b255b;
  color: #fff;
  font-weight: 900;
}
.productwrap2 .pricebox {
  width: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.valueid {
  color: #bbbbbb;
  margin-left: 5px;
}

.ant-breadcrumb-link {
  font-size: 16px;
  font-weight: bold;
}
/* .ant-breadcrumb >ol>li:nth-child(7){
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
} */
.notice_title {
  padding: 15px 10px;
  border-bottom: 1px solid #4b6cb7;
  border-top: 2px solid #4b6cb7;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.titletext {
  display: flex;
}
.titlebtn {
  float: right;
  display: flex;
  align-items: center;
}
.titlebtn .astyle {
  border-radius: 1px;
  margin-top: 0;
  margin-right: 10px;
}
.mr {
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px solid #4b6cb7;
}
.mb5 {
  margin-bottom: 5px;
}
.mr5 {
  margin-right: 5px;
}
.mb10 {
  margin-bottom: 10px;
}
.notice_title.sub {
  padding: 10px;
  border-top: none;
  display: flex;
  justify-content: space-between;
}
.notice_title .category {
  font-size: 14px;
  padding: 4px;
  background-color: #0b255b;
  color: #fff;
  margin-right: 20px;
}
.tabledivs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #000;
}
.qna .tabledivs > p:last-child {
  width: 50px;
}
.qna .tabledivs p:first-child {
  display: block;
  width: calc(100% - 50px);
  min-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.left {
  justify-content: left;
  align-items: center;
}
.ant-collapse-header {
  align-items: center;
}
.left .category {
  font-size: 14px;
  width: 130px;
  padding: 4px;
  font-weight: 900;
  color: #0b255b;
}
.answerbox {
  padding: 3px;
  font-size: 11px;
  font-weight: 900;
}

.answerbox.done {
  background-color: #0b255b;
  color: #fff;
}
.noticecon {
  padding: 10px;
  min-height: 400px;
  border-bottom: 1px solid #4b6cb7;
}
.qnacon {
  padding: 10px;
  min-height: 200px;
  border-bottom: 1px solid #4b6cb7;
  margin-bottom: 20px;
}
.astyle {
  text-align: center;
  width: 80px;
  display: block;
  margin-top: 10px;
  padding: 5px;
  font-size: 14px;
  color: #000;
  border: 1px solid #4b6cb7;
}
.mt {
  position: absolute;
  bottom: 15px;
}
.noticea {
  color: #000;
}
.qnaanswer {
  display: flex;
  padding: 10px;
  min-height: 200px;
  border-bottom: 1px solid #000;
}
.notice_title > span .ant-breadcrumb > ol > li:nth-child(7) a {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sortWrap {
  display: flex;
  margin-bottom: 20px;
}
.writeT {
  margin: 10px 0;
}
.center {
  font-weight: 900;
  color: #000;
}
/* faq네비 */
.faqnavi {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.faqnavibtn {
  width: calc(14.2% - 2px);
  height: 50px;
  font-weight: 900;
  border-radius: 1px;
  border: 1px solid #4b6cb7;
  border-right: 0px;
}
.faqnavi .faqnavibtn:last-child {
  border-right: 1px solid #4b6cb7;
}
.faqnavibtn.active {
  background-color: #0b255b;
  color: #fff;
}
.nexdivwrap {
  margin-bottom: 30px;
}
.nextdiv {
  display: flex;
  padding: 15px 0;
  border-bottom: 1px solid #4b6cb7;
  color: #000;
}
.nextdiv_t {
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px solid #4b6cb7;
}
.nextdiv_c {
  color: #000;
}

/* iot 관련 */
.iotcontain {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.iotwrap {
  border-radius: 5px;
  padding: 20px;
  width: calc(50% - 10px);
  box-shadow:
    0 3px 6px rgba(0, 0, 0, 0.16),
    0 3px 6px rgba(0, 0, 0, 0.23);
  margin-bottom: 20px;
}
.iotbox {
  display: flex;
  align-items: center;
}
.iottitle {
  font-size: 18px;
  font-weight: 900;
}
.iotthumb {
  width: 100px;
  height: 100px;
}
.iotthumb img {
  width: 100%;
  height: 100%;
}
.filewrap {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.filewrap button {
  border: none;
  outline: none;
  background: none;
  padding: 5px 0;
  cursor: pointer;
}
.filewrap button:hover {
  text-decoration: underline;
}
.nodiv {
  display: block !important;
  position: relative;
}
.modalbtn {
  z-index: 10000;
  padding: 0px;
  height: 30px;
  width: 30px;
  font-size: 16px;
  position: absolute;
  right: 0;
}
/* 메인컨트롤 */

.cardbox .card {
  width: 100%;
  height: 100%;
  display: block;
}

.cardp {
  background-color: #fff;
  font-size: 18px;
  color: #000;
  font-weight: 700;
  padding: 10px;
  margin-top: 1.25rem !important;
}
.cardbox > a > img {
  width: 100%;
  height: 200px;
}
.slick-track {
  padding: 10px 0;
}
.modalbox {
  cursor: pointer;
}

.iotallcon {
  position: relative;
}
.iotcontains {
  display: flex;
  flex-wrap: wrap;
}
.mainiotwrap {
  display: flex !important;
  width: calc(50% - 10px);
  margin-right: 10px;
  background-color: #fff;
  padding: 10px;
}
.maineventwrap {
  display: block !important;
  width: calc(50% - 10px);
  margin-right: 10px;
  background-color: #fff;
  padding: 10px;
}
.maineventwrap p {
  font-weight: bold;
  padding: 10px 0;
  font-size: 18px;
}
.eventpgCon {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.eventpgWrap {
  width: calc(50% - 30px);
  display: block;
}
.eventT {
  padding: 5px 0;
  font-size: 18px;
  font-weight: 900;
  text-align: left;
  color: #000;
}
.eventD {
  padding-bottom: 5px;
  display: flex;
  justify-content: left;
  font-size: 16px;
  font-weight: 900;
  color: #a1a1a1;
}
.maineventimg {
  width: 100%;
  height: 200px;
  overflow: hidden;
}
.maineventimg img {
  width: 100%;
}
.iotcontains .mainiotwrap:last-child {
  margin-right: 0px;
}
.iotcontains .ant-carousel {
  width: 100%;
}
.mainIotimg {
  width: 150px;
  height: 150px;
  margin-right: 10px;
}
.mainIotimg > img {
  width: 100%;
  height: 100%;
}
.maintabs {
  padding: 40px 0;
}
.maintabs .ant-tabs-tab {
  font-size: 18px;
  font-weight: 700;
}
.mainlink {
  position: absolute;
  top: 30px;
  font-size: 14px;
  right: 0;
  color: #000;
}
.noticecons {
  display: flex;
  justify-content: space-between;
}
.noticecons > div {
  width: calc(50% - 10px);
}
.conT {
  font-size: 20px;
  font-weight: 900;
  border-bottom: 1px solid #4b6cb7;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
}
.noticelist {
  display: block;
  color: #000;
  padding: 5px 0;
  border-bottom: 1px solid #bbbbbb;
}
.noticelist:last-child {
  border: unset;
}
.carticon {
  font-size: 60px;
  color: #828282;
}
.pcenter {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.tablewrap {
  padding: 10px;
  margin-top: 20px;
}
.tablediv {
  border-bottom: 1px solid #4b6cb7;
}
.tableT {
  width: 150px;
  font-size: 18px;
  font-weight: 900;
  padding: 10px;
}
.tableD {
  width: calc(100% - 150px);
  font-size: 16px;
  font-weight: 400;
  padding: 10px;
}
/* sub */
.title {
  font-size: 1.8em;
  font-weight: bold;
  line-height: 2.5em;
  margin-top: 50px;
  text-align: center;
}
.subtitle {
  font-size: 1.4em;
  border-bottom: 1px solid #4b6cb7;
  padding-bottom: 5px;
  font-weight: bold;
  padding-top: 30px;
  margin-bottom: 10px !important;
}
.textwrap > p {
  margin-bottom: 5px;
}
.startT {
  margin-bottom: 30px;
}
.inntertab {
  cursor: pointer;
  width: 150px;
  font-size: 18px;
  font-weight: 900;
  text-align: center;
  padding: 10px 0;
}

.inntertab:first-child > span {
  width: 100%;
  display: block;
  border-right: 1px solid #4b6cb7;
}
.innerdiv {
  width: 100%;
  justify-content: center;
  padding: 10px;
  border-bottom: 1px solid #4b6cb7;
}
.inntertab.clicked {
  border-bottom: 3px solid #4b6cb7;
}
.wrapT {
  width: 120px;
  font-weight: bold;
}
/* 주문목록 */
.tableH {
  padding: 10px 0;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  border-bottom: 2px solid #4b6cb7;
  border-top: 2px solid #4b6cb7;
  margin-bottom: 10px;
}
.tableB {
  margin-bottom: 10px;
}
.table1 {
  width: calc(100% - 550px);
}
.table2,
.table3,
.table4 {
  text-align: center;
  width: 100px;
}
.table5 {
  width: 200px;
}
.table5.b {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table5.b > div {
  width: calc(50% - 8px);
}
.table1,
.table2,
.table3,
.table4 {
  margin-right: 10px;
}
.tableimg {
  width: 80px;
  height: 80px;
}
.tableimg img {
  width: 100%;
  height: 100%;
}
.waitpgicon {
  font-size: 60px;
}
.nonavicon {
  padding-top: 160px;
  position: relative;
  padding-bottom: 30px;
}
.ant-tabs-tabpane {
  position: relative;
  min-height: 20vh;
  padding: 20px 0;
}
.pro_d_h {
  width: 120px;
  font-weight: bold;
  padding: 0px 10px;
}
.pro_d_b {
  width: calc(100% - 120px);
  margin-bottom: 10px;
  padding: 10px;
}
.pro_d_wrap {
  border-bottom: 1px solid #4b6cb7;

  margin-bottom: 10px;
}
.pro_d_b span {
  display: block;
  font-weight: bold;
  font-size: 14px;
}
.pro_d_b span.red {
  color: red;
}
.modalquill {
  border: 1px solid #000;
}
.modalquill .ql-toolbar {
  display: none !important;
}
.reactquills_title {
  border-radius: 0px;
  border: 1px solid #000;
}
/* 팝업 */

/* 전체 팝업 배경 */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* 더 어두운 반투명 배경 */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* 팝업 상자 */
.popup-content {
  background: linear-gradient(
    135deg,
    #f5f7fa,
    #c3cfe2
  ); /* 세련된 그라데이션 배경 */
  border-radius: 15px;
  padding: 40px;
  width: 450px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3); /* 그림자 */
  text-align: center;
  position: relative;
  animation: fadeIn 0.4s ease-in-out;
}

/* 팝업 애니메이션 */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* 팝업 제목 */
.popup-content h2 {
  font-size: 26px;
  color: #333;
  margin-bottom: 15px;
  font-weight: bold;
  letter-spacing: 1px; /* 글자 간격 */
}

/* 팝업 설명 문구 */
.popup-content p {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
  line-height: 1.6;
}

/* 체크박스와 텍스트 */
.popup-checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}

.popup-checkbox input[type='checkbox'] {
  margin-right: 10px;
  transform: scale(1.3);
}

.popup-checkbox label {
  font-size: 15px;
  color: #333;
}

/* 팝업 버튼 */
.popup-content button {
  background-color: #5a9;
  color: white;
  padding: 12px 25px;
  font-size: 16px;
  font-weight: 600;
  border: none;
  border-radius: 25px; /* 둥근 버튼 */
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    transform 0.2s;
}

.popup-content button:hover {
  background-color: #4a8; /* 호버 시 더 짙은 색 */
  transform: scale(1.05); /* 버튼이 살짝 커짐 */
}

/* 닫기 버튼 (X) */
.popup-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  font-size: 24px;
  color: #888;
  cursor: pointer;
  transition: color 0.2s ease;
}

.popup-close:hover {
  color: #555; /* 호버 시 색상 변화 */
}

/* 버튼 정렬 */
.popup-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
}

/* 팝업 */
/* 제품후기  */
.buylistwrap {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-bottom: 1px solid #4b6cb7;
}
.buylistwrap:last-child {
  border-bottom: unset;
}
.buylist_id {
  font-weight: lighter;
  font-size: 12px;
}
.buylist_title {
  padding: 10px;
  border-bottom: 1px solid #000;
  font-weight: bold;
}
.buylist_conwrap {
  padding: 5px;
}
.buylist_content {
  font-size: 14px;
}

.acnhorwrap {
  padding: 10px 0px;
}
.ant-anchor .ant-anchor-link {
  width: 20%;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}
.sdiv > button {
  border-radius: 0px;
  border: 1px solid #4b6cb7;
}
.sorting_list .ant-select-selector,
.sdiv .ant-select-selector {
  border-radius: 0px;
}
.sorting_list .ant-select,
.sdiv .ant-select {
  border: 1px solid #aec3f3;
  margin-right: 5px;
}
.sorting_list .ant-select-arrow,
.sdiv .ant-select-arrow {
  color: #4b6cb7;
}
.msort_grid {
  border-radius: 0px;
  border: 1px solid #aec3f3;
  height: 32px;
}
.lbtnstyle {
  width: 100%;
  border-radius: 0px;
  border: 1px solid #aec3f3;
}
.tabled {
  width: calc(100% - 110px);
}
.progresseswrap .dTime {
  font-weight: bold;
  width: 140px;
}
.progresseswrap .dLocation {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}
.deliverH_wrap span:first-child {
  width: 100px;
  padding: 10px;
  font-weight: bold;
  border: 1px solid #4b6cb7;
}
.deliverH_wrap span:last-child {
  width: calc(100% - 100px);
  padding: 10px;
  font-weight: bold;
  border-top: 1px solid #4b6cb7;
  border-right: 1px solid #4b6cb7;
  border-bottom: 1px solid #4b6cb7;
}
.deliverH_wrap:last-child span:first-child {
  border-top: unset;
}
.deliverH_wrap:last-child span:last-child {
  border-top: unset;
}
.progressesbox {
  margin-top: 10px;
}
.progresseswrap {
  padding: 10px;
  border-top: 1px solid #4b6cb7;
  border-right: 1px solid #4b6cb7;
  border-left: 1px solid #4b6cb7;
}
.progresseswrap:last-child {
  border-bottom: 1px solid #4b6cb7;
}
.centerbox {
  padding: 20px;
  background-color: #cacaca;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.centerbox_wrap p {
  color: #000;
  text-align: center;
  font-weight: bold;
}
.centerbox_wrap {
  padding: 10px;
  margin-bottom: 10px;
}
.centerbox_wrap_b {
  width: 80%;
  display: flex;
  justify-content: center;
}
.ant-dropdown-menu-item {
  padding: 0 !important;
  text-align: left;
}
.ant-dropdown-menu-item a {
  text-align: left;
  width: 100%;
  display: block;
  color: #0b255b;
}
.centerbox_bottom {
  position: relative;
  width: 360px;
  height: 140px;
  padding: 10px;
}

.centerbox_bottom:first-child {
  background-color: #7f7fd5;
  margin-right: 20px;
}
.centerbox_bottom:last-child {
  background-color: #86a8e7;
}
.centerbox_bottom p {
  font-weight: bold;
  color: #fff;
}
.centerbox_bottom h6 {
  font-size: 14px;
  color: #636363;
}
.centerbox_phone {
  font-size: 20px;
  font-weight: bold;
  color: #000;
}
.phoneIcon {
  text-align: right;
  font-size: 60px;
  color: #fff;
}
@media screen and (max-width: 1700px) {
  .productbox.grid1 {
    display: flex;
    flex-direction: column;
    width: calc(20% - 8px);
    margin-right: 10px;
    transition: all 0.5s ease-out;
    margin-bottom: 10px;
  }
  .productbox.grid1:nth-child(5n) {
    margin-right: 0;
  }
  .productbox.grid1:nth-child(6n) {
    margin-right: 10px;
  }
}
@media screen and (max-width: 1600px) {
  .displaybox {
    width: 100%;
    overflow: hidden;
    padding-right: 10px;
    padding-left: 10px;
  }
  .shop_sort > .shop_sort {
    padding-left: 10px;
  }
}
@media screen and (max-width: 1480px) {
  .grid2Pname {
    width: 170px;
  }
  .navigation li {
    width: 100px;
    font-size: 18px;
  }
  .shop_div {
    position: relative;
  }
  .menu-bar {
    top: 0;
    right: -300px; /* 초기에 오른쪽으로 숨김 */
    width: 100px;
    transition: right 0.3s ease-in-out;
  }
  .productbox.grid1 {
    width: calc(25% - 8px);
  }
  .productbox.grid1:nth-child(4n) {
    margin-right: 0;
  }
  .productbox.grid1:nth-child(5n) {
    margin-right: 10px;
  }
}
@media screen and (max-width: 1200px) {
  .slidebox {
    height: 500px;
  }
  .grid2Pname {
    width: 150px;
  }
  .grid2 .btnstyle {
    width: 60px;
  }
  .navigation li {
    width: 85px;
    font-size: 16px;
  }
  .productbox.grid1 {
    width: calc(33.3333% - 8px);
  }
  .productbox.grid1:nth-child(3n) {
    margin-right: 0;
  }
  .productbox.grid1:nth-child(4n) {
    margin-right: 10px;
  }
  .partwrap > div,
  .partwrap > div p img {
    width: 100%;
  }
}
/* 태블릿 */
@media screen and (max-width: 980px) {
  .slidebox {
    height: 400px;
  }
  .shop_sort {
    display: none;
  }
  .triangle {
    display: none;
  }
  .sort_navi > li.active a {
    background-color: unset;
    box-shadow: unset;
    padding-left: unset;
    padding: 5px;
  }
  .shop_sort > .shop_sort {
    padding-left: 0px;
  }
  .footer_logo,
  .footer_d {
    margin-right: 30px;
  }
  .cart_check_wrap:nth-child(2) {
    border-top: unset;
  }

  .main {
    padding-top: 52px;
    padding-left: 10px !important;
    padding-bottom: 30px !important;
    min-height: calc(100vh - 110px);
  }
  .ptx {
    padding-left: 0px !important;
  }
  /* .displaybox{
        padding: 0px 10px !important;
    } */
  .maintabs .ant-tabs-tab {
    font-size: 14px;
  }
  .noticelist {
    font-size: 14px;
  }
  .maintabs .ant-tabs {
    padding: 0px;
  }
  .mainbgwrap {
    padding: 20px 0;
  }
  /* 전체틀 */
  .shop_div {
    display: block;
    min-height: calc(100vh - 182px);
  }
  .shop_content .ant-spin-container {
    height: calc(100vh - 442px);
  }
  .pageTbox {
    margin-bottom: 0px;
  }
  .shop_content .ant-pagination {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .shop_content {
    width: 100%;
  }
  .shop_sort {
    width: 100%;
  }
  .sort_navi {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
  }
  .menu-content ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .menu-content {
    padding: 20px 5px;
  }
  .menu-bar {
    width: 70px;
  }
  .rececntT {
    display: none;
  }
  .recentbox > div {
    width: 48px;
    height: 48px;
  }
  .toggle-button.opens {
    right: 70px;
  }
  .menu-content li {
    width: 50px;
    padding: 0;
  }
  .menu-content button {
    width: 50px;
    padding: 0;
    height: 30px;
  }
  .sort_navi > li {
    width: 20%;
    background-color: #fff;
    flex: 1;
    border-right: unset;
    border-bottom: 2px solid #a0a0a08f;
  }
  .sort_navi > li a {
    color: #000;
    text-align: center;
  }
  .sort_navi > li.active a {
    color: #000;
    font-weight: 900;
  }
  .sort_navi > li.active {
    border-bottom: 2px solid #4b6cb7;
  }
  .sort_navi > li:first-child {
    border-top: unset;
  }

  /* .sort_navi>li:last-child{
        border-right: 1px solid #4b6cb7;;
    } */
  .sort_navi > li > a {
    padding: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 40px;
    line-height: 30px;
    font-weight: unset;
    font-size: 16px;
  }
  .cartcon {
    padding-bottom: 90px !important;
  }
  .sortTitle {
    display: none;
  }
  .sortWrap button {
    padding: 5px;
  }
  .sorting_list_btn {
    width: calc(100% - 100px);
  }
  .footer_bg_line {
    height: 40px;
  }
  .footer {
    height: 110px;
  }
  .footer_top {
    height: 40px;
    font-size: 14px;
    padding: 0px !important;
    align-items: center;
  }
  .footer_main {
    height: 110px;
  }
  .footerline {
    height: 40px;
    left: 175px;
  }

  .footer_top li {
    margin-right: 10px;
  }
  .footer_top li a {
    color: #000;
  }
  .footer li {
    font-size: 14px;
  }
  .footer_d_wrap li {
    font-size: 12px;
  }
  .sortWrap {
    margin-bottom: 10px;
  }
  .sorting_list {
    justify-content: right;
  }
  .shop_main {
    display: block;
  }
  .shop_main > div {
    width: 100%;
  }
  .shop_img_wrap {
    display: flex;
  }
  .shop_main .shop_img {
    width: calc(100% - 80px);
  }
  .imgslider {
    width: 80px;
    flex-direction: column;
  }
  .check_wrap {
    display: block;
  }
  .cart_check_wrap {
    width: 100%;
  }

  .dcvalue {
    left: 55px;
    top: 1px;
  }

  .tx {
    font-size: 12px !important;
  }
  .pricebox .product_d {
    font-size: 18px !important;
  }

  .counter {
    padding: 0px 5px;
    width: 25px;
  }
  .content_detail .ant-tabs-tab {
    font-size: 14px;
  }
  .ant-tabs-tabpane img {
    width: 100% !important;
  }
  .buydiv {
    width: 100%;
  }
  .buybtnwrap {
    width: 100%;
    position: relative;
    right: 0;
  }
  .grid2Pname {
    width: 120px;
  }
  .grid2 .tx {
    margin-left: 5px;
  }
  .product_wrap .count_wrap,
  .product_wrap .neeedsbtn,
  .productwrap1 .count_wrap,
  .productwrap1 .neeedsbtn {
    margin-right: 5px;
  }
  .sbtnwrap {
    display: flex;
    flex-wrap: nowrap;
  }
  .subtitle {
    font-size: 16px !important;
  }
  .textwrap > p {
    font-size: 14px;
  }
  .breacrumb {
    padding-top: 15px;
    padding-bottom: 5px;
  }
  .pageTbox .pagetitle {
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 20px;
  }
  .ant-anchor {
    overflow-x: scroll;
  }
  .ant-anchor .ant-anchor-link {
    font-size: 12px;
    padding-inline: 0;
  }
  .maintabs {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .notice_title {
    padding: 5px;
  }
  .titlebtn a,
  .titlebtn button {
    width: 50px;
  }
  .qnacon {
    margin-bottom: 5px;
  }
  .nextdiv {
    padding: 5px 0;
  }
  .tableH {
    font-size: 14px;
  }
  .table1,
  .table2,
  .table3,
  .table4,
  .table5,
  .tableB .btnstyle {
    font-size: 14px;
  }
  .table1 {
    width: calc(100% - 475px);
  }
  .table2,
  .table3,
  .table4 {
    width: 80px;
  }
  .pro_d_h {
    width: 80px;
    font-size: 14px;
    padding: 0px 5px;
  }
  .pro_d_b {
    width: calc(100% - 80px);
    margin-bottom: 10px;
    padding: 5px;
  }
  .faqnavi {
    overflow-x: scroll;
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
  }
  .faqnavi::-webkit-scrollbar {
    display: none;
  }
  .faqnavi button {
    flex-shrink: 0;
    display: block;
    width: 110px;
    height: 30px;
    font-weight: bold;
    border-radius: 1px;
  }
  .pagesub {
    line-height: unset !important;
  }
  .faqnavi button span {
    font-size: 14px;
  }
  .tableB {
    border: 1px solid #4b6cb7;
  }
  .mobile .btnstyle {
    width: 60px !important;
    height: 25px;
    line-height: 25px;
    font-size: 12px;
  }
  .tables {
    padding: 5px;
  }
  .table1,
  .table4,
  .table5 {
    width: 100%;
  }
  .tbrand {
    font-size: 12px;
    color: #828282;
    padding-bottom: 5px;
    line-height: 12px;
  }
  .tname {
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    padding-bottom: 5px;
  }
  .table4 {
    text-align: left;
    font-size: 16px;
    color: #4b6cb7;
    font-weight: 900;
    line-height: 16px;
    padding-bottom: 5px;
  }
  .table2,
  .table3 {
    display: none;
  }
  .table5.b {
    border-bottom: 1px solid #4b6cb7;
  }
  .table1,
  .table2,
  .table3,
  .table4 {
    margin-right: 10px;
  }
  .tableimg {
    width: 100px;
    height: 100px;
    margin-right: 10px;
  }
  .cart_box {
    width: calc(100% - 171px);
  }
  .cart_check_wrap {
    padding: 10px 0;
  }
  .cart_box_detail {
    width: calc(100% - 80px);
  }
  .totalp_wrap {
    width: calc(100% - 20px);
  }
  .tableT {
    font-size: 16px;
    width: 100px;
  }
  .tableD {
    font-size: 14px;
    width: calc(100% - 100px);
  }
  .ant-breadcrumb-link {
    font-size: 14px;
  }
}
/* 모바일 */

@media screen and (max-width: 720px) {
  .login_contain,
  .join_contain {
    padding: 50px 20px;
  }
  .slidebox {
    height: 300px;
  }
  .titlebtn a,
  .titlebtn button {
    font-size: 12px;
    width: 40px;
  }

  .nextdiv {
    font-size: 14px;
  }
  .nextdiv_t {
    width: 60px;
    margin-right: 10px;
    padding-right: 10px;
  }
  .nextdiv_c {
    display: block;
    width: calc(100% - 60px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .qnaanswer {
    font-size: 14px;
  }
  .qna .ant-table-tbody > tr td:last-child {
    width: calc(95% - 60px);
  }
  .qna .ant-table-tbody > tr td:last-child .tabledivs {
    width: 95%;
  }
  .ant-collapse-header {
    padding: 5px !important;
  }
  .shop_d_t_t {
    font-size: 16px !important;
  }
  .ant-table-tbody > tr > td:nth-child(2) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .tabledivs p:last-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .noticea {
    display: block;
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .productbox.grid2:nth-child(2n) {
    margin-left: 0px;
  }
  .notice_title.sub {
    font-size: 14px;
  }
  .mr {
    margin-right: 10px;
    padding-right: 10px;
  }
  .titletext .titles {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .notice_title .category {
    margin-right: 10px;
  }
  .qnacon p {
    font-size: 14px;
  }
  .tableT {
    font-size: 14px;
  }
  .tableD {
    font-size: 14px;
  }
  .subtitle {
    font-size: 14px !important;
  }
  .textwrap > p {
    font-size: 12px;
  }
  .pro_d_wrap p {
    font-size: 12px;
  }
  .cart_check_wrap .count_wrap {
    margin-right: 10px;
  }
  .cart_box {
    width: calc(100% - 166px);
  }
  .productbox.grid2 {
    width: 100%;
  }
  .login_logo {
    width: 80%;
  }

  .allbox {
    width: 100%;
  }

  .noticecons {
    display: block;
  }
  .noticecons > div {
    width: 100%;
  }
  .noticecons > div:first-child {
    margin-bottom: 20px;
  }
  .shop_d_p {
    display: block;
  }
  .shop_d_p .dcprice {
    margin-bottom: 20px;
  }
  /* .shop_d_P .countwrap{
        margin-top: 20px;
    } */
  .maineventwrap {
    width: 100%;
  }
  .iotcontains .maineventwrap:last-child {
    display: none !important;
  }

  .eventpgCon {
    display: block;
  }
  .eventpgWrap {
    width: 100%;
    margin-bottom: 10px;
  }
  .eventpgWrap:last-child {
    margin-bottom: 0px;
  }
  .productbox.grid1 {
    width: calc(50% - 8px);
  }

  .productbox.grid1:nth-child(3n) {
    margin-right: 10px !important;
  }
  .productbox.grid1:nth-child(2n) {
    margin-right: 0 !important;
  }
  .grid3 .other_wrap {
    display: none;
  }
  .grid3 .pricebox {
    width: 90px;
  }
  .grid3 .gridbtnwrap,
  .grid3 .neeedsbtn,
  .grid3 .cartbtn {
    width: 80px;
  }
  .productwraps {
    width: 200px;
  }
  .footer_logo {
    display: none;
  }
  .footer_bg_line {
    height: 20px;
  }
  .footer,
  .footer_main {
    height: 100px;
  }
  .footer_top {
    height: 30px;
  }
  .footer_top li {
    height: 30px;
    font-size: 12px !important;
  }
  .footerline {
    height: 30px;
    left: 0px;
  }
  .footer_d {
    margin-right: 10px;
  }
  .footer_d {
    width: 50%;
  }
  .footer_d_wrap {
    padding: 0px;
  }
  .footer_top li a {
    color: #000;
  }
  .footer_d_wrap li {
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .grid3 .grid31 {
    width: calc(100% - 210px) !important;
  }
  .grid3 .grid32 {
    width: 80px !important;
  }
  .pricebox .product_d,
  .pricebox .product_d span {
    font-size: 14px !important;
  }
  .grid34 {
    width: 70px !important;
  }
  .grid35 {
    width: 50px !important;
  }
  .grid35 > button {
    width: 50px !important;
  }
  .grid1 .counterbtrn_wrap > button {
    width: calc((100% - 90px) / 2) !important;
  }
  .sort_navi > li > a {
    font-size: 14px;
  }
  .shop_d_box button,
  .shop_d_box a {
    font-size: 16px;
    height: 30px !important;
  }
  .shopcompare .shopcomaprebox {
    width: 25%;
  }
  .shopcompare .shopcomaprebox:nth-child(5) {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .slidebox {
    height: 250px;
  }
  .sort_navi > li > a {
    font-size: 12px;
  }
  .grid3 .grid31 {
    width: calc(100% - 190px) !important;
  }
  .grid3 .grid32 {
    width: 70px !important;
  }
  .grid32 .tx {
    display: none;
  }
  .shop_img_wrap {
    display: block;
  }
  .shop_main .shop_img {
    width: 100%;
  }
  .imgslider {
    width: 100%;
    flex-direction: row;
  }
  .grid35 {
    width: 30px !important;
  }
  .grid35 > button {
    width: 30px !important;
  }
  .maineventimg img {
    width: 100%;
    height: 100%;
  }
  .shopcompare .shopcomaprebox {
    width: 33.3333333%;
  }
  .shopcompare .shopcomaprebox:nth-child(4) {
    display: none;
  }
  .cart_box_img {
    width: 50px;
    height: 50px;
  }
  .cart_box_detail {
    width: calc(100% - 50px);
  }
  .tname {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.typing-demo {
  width: 28ch;
  /* ch : 25개의 문자열을 포함 */
  animation:
    typing 5s steps(28),
    blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  font-size: 30px;
}
