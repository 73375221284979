.compare-table .table-column {
    text-align: center;
    font-weight: bold;
}

.compare-table .ant-table-cell-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.compare-table {
    background-color: #fff;
    border-radius: 8px;
}

.delete-modal .ant-modal-title {
    text-align: center;
    font-weight: bold;
}

.delete-modal .ant-modal-content {
    border-radius: 8px;
}

.delete-modal .ant-modal-footer {
    display: flex;
    justify-content: center;
}

.ant-btn-danger {
    background-color: #ff4d4f;
    border-color: #ff4d4f;
}

.ant-btn-danger:hover {
    background-color: #ff7875;
    border-color: #ff7875;
}
